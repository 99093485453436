<template lang="pug">
div
  .d-flex.justify-content-start
    .campaign-integration-card-logo.new-logo(:style="{ background: backgroundColor }")
      integration-logo(:type="integration.global.type" justImage)
    .campaign-integration-card-details
      .campaign-integration-card-name {{ $t(`integrations.${integration.global.type}.name`) }}
      .campaign-integration-card-name-details(
        v-if="integration.global.type === 'shopifyCustomer'"
        :title="integration.global.data.shopifyDomain"
      ) {{ integration.global.data.shopifyDomain }}
      .campaign-integration-card-name-details(v-else :title="integration.global.data.name") {{ integration.global.data.name }}
    .campaign-integration-card-warning.ml-3(v-if="noInput")
      OmToast(color="warning" type="side" :closable="false")
        span {{ $t('noInputField') }}
    .campaign-integration-card-actions
      slot(name="action")
</template>

<script>
  import IntegrationLogo from '@/components/IntegrationLogo.vue';
  import { getIntegrationColors } from '@om/integrations';

  export default {
    components: {
      IntegrationLogo,
    },

    props: {
      integration: {
        type: Object,
      },
      small: {
        type: Boolean,
        default: false,
      },
      noInput: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      backgroundColor() {
        return getIntegrationColors(this.integration.global.type);
      },
    },
  };
</script>
