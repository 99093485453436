<template lang="pug">
.button-holder
  .d-flex.button-holder-add(v-if="recommendationState === RECOMMENDATION_STATES.ADD")
    om-chip.integration-recommendation(color="secondary") {{ $t('recommendedIntegration') }}
    om-button(secondary @click="addIntegration(RECOMMENDATION_STATES.ADD)") {{ $t('integrationFlow.addIntegration', { type: capitalizeFirstLetter(this.type) }) }}
  .button-holder-select(v-if="recommendationState === RECOMMENDATION_STATES.SELECT")
    om-button(secondary @click="addIntegration(RECOMMENDATION_STATES.SELECT)") {{ $t('integrationFlow.select') }}
  .button-holder-finish(v-if="recommendationState === RECOMMENDATION_STATES.FINISH")
    om-button(secondary @click="addIntegration(RECOMMENDATION_STATES.FINISH)") {{ $t('integrationFlow.finishSetup') }}
</template>
<script>
  import { track } from '@/services/xray';
  import { capitalizeFirstLetter } from '@/util';
  import GET_GLOBAL_INTEGRATIONS from '@/graphql/GetGlobalIntegrations.gql';
  import { RECOMMENDATION_STATES } from '@om/integrations';
  import { FLOW_STATES } from '@/helpers/integration/newIntegrationFlow';

  export default {
    props: {
      type: {
        type: String,
        required: true,
      },
      campaignId: {
        required: true,
      },
      recommendationState: {
        type: String,
        default: null,
      },
    },

    data() {
      return {
        RECOMMENDATION_STATES,
      };
    },

    methods: {
      capitalizeFirstLetter,
      async addIntegration(state) {
        track('integrationRecommendationClicked', {
          type: state,
        });

        let params = {
          integrationType: this.type,
          state: FLOW_STATES.SELECT_GLOBAL,
        };

        if (state === RECOMMENDATION_STATES.SELECT) {
          return this.$modal.show('integration-new-flow-select-global', params);
        }

        if (state === RECOMMENDATION_STATES.ADD) {
          params = {
            integrationType: this.type,
            state: FLOW_STATES.NEW,
          };
        } else if (state === RECOMMENDATION_STATES.FINISH) {
          const integration = await this.fetchGlobalIntegrationByType(this.type);
          params = {
            integrationType: this.type,
            state: FLOW_STATES.SELECT_GLOBAL,
            globalIntegrationId: integration._id,
          };
        }

        this.$modal.show('integration-new-flow', params);
      },

      async fetchGlobalIntegrationByType(type) {
        const {
          data: { globalIntegrations },
        } = await this.$apollo.query({
          query: GET_GLOBAL_INTEGRATIONS,
        });

        return globalIntegrations.find((integration) => integration.type === type);
      },
    },
  };
</script>
<style lang="sass" scoped>
  // todo after button component support to set minimum width, remove this
  .button-holder
    button
      min-width: 140px
      @media screen and (min-width: 1400px)
        margin-left: 18.4375rem
      @media screen and (max-width: 1399px)
        margin-left: 5rem
      @media screen and (max-width: 767px)
        margin-left: 0
      @media screen and (max-width: 575px)
        margin-left: 0
</style>
