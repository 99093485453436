<template lang="pug">
.col-auto.campaign-integration-card.campaign-integration-card-analytics.cursor-pointer.new-flow(
  @click="$router.push({ name: 'domains', query: { domain: domain.domain } })"
)
  .d-flex.justify-content-start
    .campaign-integration-card-logo.new-logo
      img(:src="require('@/assets/admin/svg/analytics3.svg')")
    .campaign-integration-card-details
      .campaign-integration-card-name
        .d-flex
          span Google Analytics
          .ml-3.d-flex.align-items-center
            .font-size-0--875.text-center.text-color-success-green.font-weight-semibold(
              v-if="isAnalyticsEnabled"
            ) {{ $t('enabled') }}
            .font-size-0--875.text-center.text-color-error-red.font-weight-semibold(v-else) {{ $t('disabled') }}
      .campaign-integration-card-name-details {{ $t('googleAnalytics') }}
</template>

<script>
  import domainInfos from '@/mixins/domainInfos';

  export default {
    mixins: [domainInfos],

    props: {
      campaign: {
        type: Object,
      },
      small: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>

<style lang="sass">
  .campaign-integration-card-analytics
    .campaign-integration-card-logo
      background-color: white
  .new-flow
    .campaign-integration-card-logo
      min-width: 4.5rem
      min-height: 4.5rem
</style>
