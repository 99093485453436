<template lang="pug">
div
  om-modal(
    :name="globalIntegrationModalName"
    :modalClasses="globalIntegrationModalName"
    :width="800"
    color="light"
    :scrollable="true"
    @beforeOpen="beforeOpen"
  )
    template(#modal-header)
      .row
        .col
          .font-weight-bold.font-size-1--25 {{ $t('integrationFlow.integrationModal.modalTitle', { type: capitalizeFirstLetter(integrationType) }) }}
      .brand-modal-action-icon.cursor-pointer(@click="$modal.hide(globalIntegrationModalName)")
        close-icon(:width="12" :height="12" color="#AAB1C1")
    template(#modal-body)
      .integrations
        transition-group(name="fade" mode="out-in")
          template(v-for="(integration, index) in filteredIntegrations")
            .campaign-integration-card.campaign-integration-card-analytics.new-flow.d-flex(
              @click="selectIntegration(integration)"
              :key="index"
            )
              .d-flex.justify-content-start.align-items-center
                .campaign-integration-card-logo.new-logo(
                  :style="{ background: getBackgroundColor() }"
                )
                  integration-logo(:type="integrationType" justImage)
                .d-flex.flex-column.align-self-center.ml-3
                  .integration-name {{ integration.data.name }}
                  template(v-for="authField in integrationAuthFields(integrationType)")
                    .api-key {{ $t(`integrationFlow.${integrationType}.${authField}`) }} {{ integration.data[authField] }}
              .d-flex.align-self-center.action-button
                om-button(
                  icon="trashAlt"
                  iconSize="1.5rem"
                  iconOnly
                  ghost
                  style="color: #505763"
                  @click.stop="deleteGlobalIntegration(integration._id)"
                )
      campaign-settings-skeleton(
        key="skeleton"
        :showStructureLines="false"
        @click="addNewIntegration"
      )
</template>
<script>
  import GET_CAMPAIGNS_BY_INTEGRATION from '@/graphql/GetCampaignsByIntegration.gql';
  import REMOVE_GLOBAL_INTEGRATION from '@/graphql/RemoveGlobalIntegration.gql';
  import { getIntegrationAuthFields, getIntegrationColors } from '@om/integrations';
  import { capitalizeFirstLetter } from '@/util';
  import IntegrationLogo from '@/components/IntegrationLogo.vue';
  import CampaignSettingsSkeleton from '@/components/CampaignSettings/Skeleton.vue';
  import { track } from '@/services/xray';
  import { FLOW_STATES } from '@/helpers/integration/newIntegrationFlow';

  export default {
    components: {
      IntegrationLogo,
      CampaignSettingsSkeleton,
    },

    props: {
      globalIntegrations: {
        type: Array,
        required: true,
      },
    },

    data() {
      return {
        globalIntegrationModalName: 'integration-new-flow-select-global',
        integrationType: '',
        integrationState: '',
      };
    },

    computed: {
      filteredIntegrations() {
        return this.globalIntegrations.filter(
          (integration) => integration.type === this.integrationType,
        );
      },
    },

    methods: {
      capitalizeFirstLetter,
      getBackgroundColor() {
        return getIntegrationColors(this.integrationType);
      },

      addNewIntegration() {
        this.$modal.hide(this.globalIntegrationModalName);
        this.$modal.show('integration-new-flow', {
          integrationType: this.integrationType,
          state: FLOW_STATES.NEW,
        });
      },

      async deleteGlobalIntegration(integrationId) {
        const { length, campaigns } = await this.checkIntegrationIsInOtherCampaign(integrationId);
        if (length) {
          this.$modal.show('integration-remove', { campaigns, integrationId });
        } else {
          try {
            const {
              data: { removeIntegration },
            } = await this.$apollo.mutate({
              mutation: REMOVE_GLOBAL_INTEGRATION,
              variables: {
                integrationId,
              },
            });
            if (removeIntegration) {
              this.$notify({
                type: 'success',
                text: this.$t('notifications.removeSuccess'),
              });
              this.$emit('refetchIntegrations', 'accountSettings');
            } else {
              this.notifyError();
            }
          } catch (error) {
            this.notifyError();
          }
        }
      },

      async checkIntegrationIsInOtherCampaign(integrationId) {
        try {
          const {
            data: { campaigns },
          } = await this.$apollo.query({
            query: GET_CAMPAIGNS_BY_INTEGRATION,
            variables: {
              integrationId,
            },
          });
          return { length: campaigns.length, campaigns };
        } catch (error) {
          this.notifyError();
        }
      },

      selectIntegration(integration) {
        track('usingIntegration', { type: this.integrationType, modal: 'dedicated', version: 2 });
        this.$modal.hide(this.globalIntegrationModalName);
        this.$modal.show('integration-new-flow', {
          integrationType: this.integrationType,
          state: this.integrationState || FLOW_STATES.EDIT,
          globalIntegrationId: integration._id,
        });
      },

      beforeOpen(event) {
        this.integrationType = event.params.integrationType;
        this.integrationState = event.params.state;
      },

      notifyError() {
        this.$notify({
          type: 'error',
          text: this.$t('notifications.removeError'),
        });
      },

      integrationAuthFields(integrationType) {
        return getIntegrationAuthFields(integrationType);
      },
    },
  };
</script>
